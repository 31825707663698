// Bring in a couple files from Bootstrap so we can use the clearfix and vendor mixins (for transition, etc)
@import "../../scss/bootstrap/mixins/_clearfix";
@import "../../scss/bootstrap/mixins/_vendor-prefixes";

// Bring in the vars and custom mixins defined in the WCMC Bootstrap theme. Sets the WCMC Colors and Breakpoints
@import "../../scss/_variables";
@import "../../scss/_mixins";

/* =Two Column
----------------------------------------------------------*/

.panel-twocol {
  @include clearfix;
  position: relative;
}

// This overrides a style coming from Radix Layouts. Our gutter is a little bigger.
.container .container {
  margin-left: -20px;
  margin-right: -20px;
}

.hero-image img {
  width: 100%;
  height: auto;
  margin: 0 0 20px
}